body {
  background-color: #000;
}

canvas {
  background-color: #fff;
  margin: 0 auto;
}

/*# sourceMappingURL=index.578660c3.css.map */
